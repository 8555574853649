<style lang="less" scoped>
.main {
  width: 100%;
  height: 100%;
  position: relative;
  background: rgb(13, 14, 18);
  &-header {
    width: 100%;
    height: 48px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    background: rgb(13, 14, 18);
    color: rgb(124, 126, 139);
    img {
      width: 36px;
      vertical-align: middle;
    }
    span {
      vertical-align: middle;
      color: rgb(68, 95, 216);
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 2px;
    }
    &-left {
      line-height: 48px;
    }
    &-right {
      line-height: 48px;
      margin-right: 20px;
    }
  }
}

.swiper-container {
  width: 100%;
  height: 100vh;
}
.swiper-slide img {
  width: 100%;
}
</style>

<template>
  <div class="main">
    <div class="main-header">
      <div class="main-header-left">
        <img src="../../assets/menu.png"
             alt="">
        <span>
          CECC
        </span>
      </div>
      <div class="main-header-right"
           @click="toLogin">
        注册 | 登录
      </div>
    </div>
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <img src="../../assets/Bitmap.png"
               alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';
import 'swiper/css/swiper.min.css';

export default {
  name: 'mobile',
  data () {
    return {
    }
  },
  components: {},
  created () {
    if (!(navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
      this.$router.push({
        name: 'home'
      })
    }
  },
  mounted () {
    new Swiper('.swiper-container', {
      direction: 'vertical',
      mousewheel: true,
      keyboard: true,
      // touchRatio: 2
      // pagination: {
      //   el: '.swiper-pagination',
      // },
      // navigation: {
      //   nextEl: '.swiper-button-next',
      //   prevEl: '.swiper-button-prev',
      // },
    });
  },
  methods: {
    toLogin () {
      // this.$router.push({
      //   name: 'login'
      // })
    }
  }
}
</script>
